import * as React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Title, { TitleVariant } from "../Title";

interface BlogSummary {
  className?: string;
}

interface IDataQuery {
  allStrapiArticle: {
    nodes: Array<{
      id: string;
      Slug: string;
      Titre: string;
      publishedAt: String;
      Couverture: Array<{
        caption: string;
        localFile: ImageDataLike;
      }>;
    }>;
  };
}

const BlogSummary = ({ className }: BlogSummary) => {
  return (
    <StaticQuery
      query={graphql`
        query LastThreePost {
          allStrapiArticle(sort: { fields: publishedAt }, limit: 3) {
            nodes {
              Titre
              Slug
              Auteurs
              publishedAt
              Couverture {
                caption
                localFile {
                  childImageSharp {
                    gatsbyImageData(aspectRatio: 1.77)
                  }
                }
              }
            }
          }
        }
      `}
      render={(data: IDataQuery) => {
        if (!data) {
          return null;
        }

        return (
          <div className={className}>
            <Title
              variant={TitleVariant.h2}
              className="text-center mt-secondary"
              text="Sur le même thème"
            />
            {data.allStrapiArticle.nodes.map((article) => {
              const image = getImage(article.Couverture[0]?.localFile);

              return (
                <article
                  className="flex my-6 cursor-pointer rounded-2xl shadow-card mt-secondary"
                  key={article.id}
                  onClick={() =>
                    (window.location.href = `/blog/${article.Slug}`)
                  }
                >
                  <div className="flex w-40">
                    {image ? (
                      <GatsbyImage
                        image={image}
                        alt={article.Couverture[0].caption}
                        className="rounded-l-2xl"
                      />
                    ) : (
                      <StaticImage
                        src="../../images/photos/blog-internet-ameliorer.png"
                        alt="améliorer son site"
                        className="rounded-l-2xl"
                      />
                    )}
                  </div>
                  <div className="p-4 ml-2">
                    <p className="text-sm lg:text-lg">{article.Titre}</p>
                    <p className="mt-4 text-sm italic text-gray-400">
                      publié le{" "}
                      {typeof article.publishedAt === "string" &&
                        format(new Date(article.publishedAt), "dd MMMM yyyy", {
                          locale: fr,
                        })}
                    </p>
                  </div>
                </article>
              );
            })}
          </div>
        );
      }}
    />
  );
};

export default BlogSummary;

import * as React from "react";
import { graphql } from "gatsby";
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Layout from "../components/Layout";
import MarkdownIt from "markdown-it";
import ReplaceLink from "markdown-it-replace-link";
import BlogSummary from "../components/BlogSummary";
import Button, { ButtonVariant } from "../components/Button";

interface IArticleCard {
  data: {
    strapiArticle: {
      Titre: string;
      Auteurs: string;
      publishedAt: String;
      Couverture: Array<{
        caption: string;
        localFile: ImageDataLike;
      }>;
      Contenu: {
        data: {
          childMarkdownRemark: {
            timeToRead: number;
            rawMarkdownBody: string;
          };
        };
      };
    };
  };
}

const ArticlePage = ({ data }: IArticleCard) => {
  const article = data.strapiArticle;
  const image = getImage(article.Couverture[0]?.localFile);
  const regex = new RegExp("^/uploads/", "i");
  // @ts-ignore
  const md = new MarkdownIt({
    breaks: true,
    linkify: true,
    typographer: true,
    quotes: ["«\xA0", "\xA0»", "‹\xA0", "\xA0›"],
    replaceLink: (link: string) =>
      regex.test(link) ? process.env.STRAPI_API_URL + link : link,
  }).use(ReplaceLink);

  const formattedContenu = md.render(
    article.Contenu.data.childMarkdownRemark.rawMarkdownBody
  );

  return (
    <Layout
      title={article.Titre}
      metaTitle="Blog UX Design"
      description="Découvrez à travers nos articles, des réflexions autour de l’UX, des bonnes pratiques sur le design éthique, des retours d’expériences de professionnels"
    >
      <div className="mt-main font-work">
        <p>
          {typeof article.publishedAt === "string" &&
            format(new Date(article.publishedAt), "dd MMMM yyyy", {
              locale: fr,
            })}
        </p>
        <p>par {article.Auteurs}</p>
        <p>
          Temps de lecture :{" "}
          {article.Contenu.data.childMarkdownRemark.timeToRead} min
        </p>
      </div>
      {image ? (
        <div className="text-center article-couverture">
          <GatsbyImage
            image={image}
            alt={article.Couverture[0].caption}
            className="max-w-xs mt-main"
          />
          <p className="italic font-work">
            crédits photo : {article.Couverture[0].caption}
          </p>
        </div>
      ) : (
        <StaticImage
          src="../images/photos/blog-internet-ameliorer.png"
          alt="améliorer son site"
          className="max-w-xs rounded-l-2xl mt-main"
        />
      )}

      <div
        className="mt-main article-contenu font-work"
        dangerouslySetInnerHTML={{
          __html: formattedContenu,
        }}
      />

      <BlogSummary />
      <Button
        variant={ButtonVariant.secondary}
        text="Retour au blog"
        to="/blog"
        replace
        className="mx-auto mt-20"
      />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    strapiArticle(Slug: { eq: $slug }) {
      Titre
      Auteurs
      publishedAt
      Couverture {
        caption
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Contenu {
        data {
          childMarkdownRemark {
            timeToRead
            rawMarkdownBody
          }
        }
      }
    }
  }
`;

export default ArticlePage;
